import React, { useEffect, useState } from 'react'
import styles from '../../../assets/styles/home.module.scss';
import { Grid } from '@material-ui/core';
import { InputText, InputSelect, PhoneNumber, CustomRadioButton, TextArea, RadioButtonDialog, RangeSlider, Calender, MetricInput, ProgressSlider } from '../../../shared_ui_components';
import { SectionCounter, KeyValuePair, RegionSearch, NumberChange, Shimmer } from './index'
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import PageTitleBlk from './PageTitleBlk';
import { fieldValidation } from '../../../utils/formValidation';
import { toastFlashMessage } from '../../../utils';
import { isMobile, isTablet } from 'react-device-detect'

export default function SectionForm(props){
    const { section, warning, setWarning, setOpenWarning, setNextSectionIndex, activeIndex } = props

    const [sectionDetail, setSectionDetail] = useState([])
    const [loader, setLoader] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [validateNewInput, setValidateNewInput] = useState({})

    const fetchSectionDetailApi = (id) => {
        setLoader(true)
        globalGetService(`/product/section/${id}/ `)
        .then(response => {
            setLoader(false)
            if(response.statusCode == 200){
                setSectionDetail(response.data.json)
            }
        })
    }
    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    useEffect(() => {
        if(section.uuid){
            fetchSectionDetailApi(section.uuid);
            setWarning(false);
            scrollTop();
            setValidateNewInput({})
        }
    }, [section.uuid])

    const handleChange = (questionId, value) => {
        sectionDetail.every(subSection => {
            const question = subSection.questions.find(question => question.id === questionId)
            if(question){
                const questionUpdated = {
                    ...question,
                    answer: value,
                    error: ''
                }
                subSection.questions = subSection.questions.map(question => question.id === questionId ? questionUpdated : question)
                const updatedSectionDetail = sectionDetail.map(sub => sub.title === subSection.title ? subSection : sub)
                setSectionDetail(updatedSectionDetail)
                setValidateNewInput({
                    ...validateNewInput,
                    [question.id] : ''
                })
                setWarning(true);
                return false
            }else{
                return true
            }
        })
    }

    const handleError = (questionId, value) => {
        sectionDetail.every(subSection => {
            const question = subSection.questions.find(question => question.id === questionId)
            if(question && question.err_msg && question.err_obj ){
                const questionUpdated = {
                    ...question,
                    error : question['err_msg'][fieldValidation({...question.err_obj, fieldval: value, requiredFlag: false})]
                }
                subSection.questions = subSection.questions.map(question => question.id === questionId ? questionUpdated : question)
                const updatedSectionDetail = sectionDetail.map(sub => sub.title === subSection.title ? subSection : sub)
                setSectionDetail(updatedSectionDetail)
                return false
            }else{
                return true
            }
        })
    }

    const handleSubmitSection = (type="") => {
        if(type === "next"){
            setSubmitLoader(true)
            let validateNewInput = {}
            let updatedSectionDetail = []
            updatedSectionDetail = sectionDetail.map(subSection => {
                const updatedSubsectionQuestions = subSection.questions.map((question, index) => {
                    if(question.err_msg && question.err_obj && !['stepper', 'key_value_pair'].includes(question.input_type)){
                        validateNewInput = {
                            ...validateNewInput,
                            [question.id]: question['err_msg'][fieldValidation({...question.err_obj, fieldval: question.answer, requiredFlag: false})]
                        }
                        const questionUpdated = {
                            ...question,
                            error: validateNewInput[question.id]
                        }
                        // return subSection.questions[index] = questionUpdated
                        return questionUpdated
                    }else if(question.input_type == "stepper" || question.input_type == "key_value_pair"){
                        const requiredFlag = question.answer.every(answer => {
                            let noOfAnsweredKeys = 0
                            let totalNoOfKeys = Object.keys(answer).length
                            Object.keys(answer).forEach(key => {
                                if(answer[key] != "" && key != "error"){
                                    noOfAnsweredKeys += 1
                                }
                            })
                            if(noOfAnsweredKeys > 1 && noOfAnsweredKeys < (question.input_type == "stepper" ? totalNoOfKeys : totalNoOfKeys - 1)){
                                return false
                            }else{
                                return true
                            }
                        })
                        if(!requiredFlag){
                            validateNewInput = {
                                ...validateNewInput,
                                [question.id] : "All fields are mandatory!"
                            }
                            const questionUpdated = {
                                ...question,
                                error: validateNewInput[question.id]
                            }
                            return questionUpdated
                        }
                        if(question.input_type == "stepper" && !validateNewInput[question.id]){
                            let equitySum = 0
                            question.answer.forEach(answer => {
                                equitySum += Number(answer['equity'])
                            })
                            if(equitySum > 100){
                                validateNewInput = {
                                    ...validateNewInput,
                                    [question.id] : "Equity share percentage sum can't be greater than 100"
                                }
                                const questionUpdated = {
                                    ...question,
                                    error: validateNewInput[question.id]
                                }
                                return questionUpdated
                            }else{
                                return question
                            }
                        }else if(question.input_type == "key_value_pair" && !validateNewInput[question.id]) {
                            let updatedAnswers = question.answer
                            question.headers.forEach(header => {
                                if(header && header.err_msg && header.err_obj){
                                    updatedAnswers = question.answer.map(ans => {
                                        let updatedAns = {
                                            ...ans,
                                            error : header['err_msg'][fieldValidation({...header.err_obj, fieldval: ans[header.key], requiredFlag: false})]
                                        }
                                        if(updatedAns.error){
                                            validateNewInput = {
                                                ...validateNewInput,
                                                [question.id] : updatedAns.error
                                            }
                                        }
                                        return updatedAns
                                    })
                                }
                            })
                            const questionUpdated = {
                                ...question,
                                answer : updatedAnswers,
                            }
                            return questionUpdated
                        }else{
                            return question
                        }
                    }
                    else if(question.input_type == "range_slider"){
                        if((question.answer.from &&  !question.answer.to) || (!question.answer.from &&  question.answer.to)){
                            validateNewInput = {
                                ...validateNewInput,
                                [question.id] : "required error"
                            }
                        }
                        const questionUpdated = {
                            ...question,
                            error: validateNewInput[question.id]
                        }
                        return questionUpdated
                    }
                    else{
                        return question
                    }
                })
                return {...subSection, questions: updatedSubsectionQuestions}
            })

            if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
                let formData = []
                sectionDetail.forEach(subSection => {
                    let updatedAnsweresArray = subSection.questions.map(question => {
                        if(question.input_type == "stepper" || question.input_type == "key_value_pair"){
                            let updatedAnswer = []
                            question.answer.forEach((answer) => {
                                if(!Object.keys(answer).some(k => k != "error" && answer[k] == "") ){
                                    updatedAnswer.push(answer)
                                }
                            })
                            updatedAnswer = updatedAnswer.map((answer, index) => {return {...answer, order: index+1} })
                            return { question_id: question.id, answer: updatedAnswer.length ? updatedAnswer : '' }
                        }else if(question.input_type == "range_slider"){
                            return { question_id : question.id, answer : (question.answer?.from != null && question.answer?.to != null) ? question.answer : ''}
                        }else{
                            return { question_id: question.id, answer: typeof question.answer == "string" ? question.answer.trim() : question.answer }
                        }
                })
                    formData = [...formData, ...updatedAnsweresArray]
                })
                // console.log('formData', formData)
                globalPostService(`/product/update-section/${section.uuid}/`, formData)
                .then(response => {
                    if(response.statusCode == 200){
                        //section updated successfully
                        toastFlashMessage(response.message, 'success')
                        const res = response.data
                        if(res.hasOwnProperty('json')){
                            delete res['json']
                        }
                        props.forceUpdateSectionList(res)
                        props.handleChangeSection(activeIndex + 1)
                    }
                })
            }else{
                setValidateNewInput(validateNewInput)
                setSubmitLoader(false)
                setSectionDetail(updatedSectionDetail)
            }
        }else{

        }
    }

    const getQuestionType = (question={}, questions=[]) => {
        switch(question.input_type){
            case 'textfield':
                return (
                    <InputText
                        label={question.title}
                        placeholder={question.placeholder}
                        required={question.mandatory}
                        value={question.answer}
                        onChange={(e) => handleChange(question.id, e.target.value)}
                        onBlur={(e) => handleError(question.id, e.target.value)}
                        error={question.error}
                    />
                )
            case 'drop_down':
                return (
                    <InputSelect
                        label={question.title}
                        placeholder={question.placeholder}
                        required={question.mandatory}
                        value={question.answer}
                        onChange={(value) => handleChange(question.id, value)}
                        options={question.option_values}
                        onBlur={(e) => handleError(question.id, e.target.value)}
                        error={question.error}
                    />
                )
            case 'phone_number':
                return(
                    <PhoneNumber
                        label={question.title}
                        placeholder={question.placeholder}
                        required={question.mandatory}
                        onChange={(value) => handleChange(question.id, value)}
                        onBlur={(e) => handleError(question.id, e.target.value)}
                        error={question.error}
                        defaultCountry="US"
                        value={question.answer}
                    />
                )
            case 'stepper':
                return(
                    <SectionCounter
                        section={question}
                        handleChange={handleChange}
                    />
                )
            case 'radio_button':
                if(question.multi_select){
                    return(
                        <RadioButtonDialog
                            label={question.title}
                            required={question.mandatory}
                            handleChange={(value) => {
                                if(question.answer.includes(value)){
                                    const index = question.answer.indexOf(value)
                                    question.answer.splice(index, 1)
                                    handleChange(question.id, question.answer)
                                }else{
                                    handleChange(question.id, [...question.answer, value])
                                }
                            }}
                            options={question.option_values}
                            value={question.answer}
                            btnText={question.value}
                            handleSubmit={(value) => handleChange(question.id, value)}
                        />
                    )
                }else{
                    return (
                        <CustomRadioButton
                            label={question.title}
                            required={question.mandatory}
                            handleChange={(value) => {
                                handleChange(question.id, value)
                            }}
                            options={question.option_values}
                            value={question.answer}
                        />
                    )
                }
            case 'textarea':
                return (
                    <TextArea
                        label={question.title}
                        placeholder={question.placeholder}
                        required={question.mandatory}
                        onChange={(e) => handleChange(question.id, e.target.value)}
                        onBlur={(e) => handleError(question.id, e.target.value)}
                        error={question.error}
                        value={question.answer}
                    />
                )
            case 'progressbar':
                return (
                    <ProgressSlider
                        label={question.title}
                        required={question.mandatory}
                        handleChange={(value) => handleChange(question.id, value)}
                        question={question}
                        value={question.answer}
                        error={question.error}
                        showlimit={question.show_limit}
                    />
                )
            case 'key_value_pair':
                return(
                    <KeyValuePair
                        label={question.title}
                        required={question.mandatory}
                        section={question}
                        handleChange={handleChange}
                    />
                )
            case 'region_search':
                return (
                    <RegionSearch
                        question={question}
                        handleChange={handleChange}
                        questions={questions}
                        error={question.error}
                    />
                )
            case 'number_range':
                return(
                    <NumberChange
                        section={question}
                        handleChange={handleChange}
                    />
                )
            case 'range_slider':
                return(
                    <RangeSlider
                        label={question.title}
                        required={question.mandatory}
                        handleChange={(value) => handleChange(question.id, value)}
                        question={question}
                        // value={(question.answer.from || question.answer.to) ? [question.answer.from, question.answer.to] : null}
                        error={question.error}
                    />
                )
            case 'number':
                return (
                    <InputText
                        label={question.title}
                        placeholder={question.placeholder}
                        required={question.mandatory}
                        value={question.answer}
                        onChange={(e) => handleChange(question.id, e.target.value)}
                        type="number"
                        error={question.error}
                        onBlur={(e) => handleError(question.id, e.target.value)}
                    />
                )
            case 'calender':
                return (
                    <Calender
                        label={question.title}
                        placeholder={question.placeholder}
                        required={question.mandatory}
                        value={question.answer}
                        onChange={(date) => handleChange(question.id, date)}
                        error={question.error}
                    />
                )
            case 'metricinput':
                return (
                    <MetricInput
                        label={question.title}
                        required={question.mandatory}
                        placeholder={question.placeholder}
                        unitPosition={question.unit_position}
                        unit={question.unit}
                        value={question.answer}
                        handleChange={(e) => handleChange(question.id, e.target.value)}
                        error={question.error}
                    />)
        }
    }
    return(
        <>
        <PageTitleBlk
            activeSection={section}
            handleSubmitSection={handleSubmitSection}
            handleChange={handleChange}
            warning={warning}
            handleChangeSection={props.handleChangeSection}
            setOpenWarning={setOpenWarning}
            errorObj={validateNewInput}
            setNextSectionIndex={setNextSectionIndex}
            activeIndex={activeIndex}
            handleClickMenu={props.handleClickMenu}
        />
        <Grid item xs={12} md={8} sm={12}>
            <div className={styles.sectionFormWrapper + ' ' + `${section.slug}`}>
                <h3 className={styles.sectionTitle + ' heading2'}>{section?.title}</h3>
                {
                    loader ?
                    <Shimmer type="Form" />
                    :
                    sectionDetail.length ? sectionDetail.map((subSection, index) =>
                    <Grid container spacing={isMobile ? 1 : 2} key={index} className={styles.subSectionWrapper}>
                        {subSection.title ? <Grid item xs={12}>
                            <h3 className={`heading4Medium ${styles.subSectionTitle}`}>{subSection?.title}</h3>
                        </Grid> : null}
                        {
                            subSection.questions.map((question, index) => <Grid key={index} item xs={12} md={question?.grid}>
                                {getQuestionType(question, subSection.questions)}
                            </Grid> )
                        }
                    </Grid>) : null
                }
            </div>
        </Grid>
        </>
    )
}
