import Result from './containers/Result';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';

export const resultRoutes = [
  {
    path: '/result/:assessmentId?',
    component : pageLayoutHoc(Result),
    key: 'result'
  }
]
