import Home from './containers/Home';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';

export const homeRoutes = [
  {
    path: '/:formslug?',
    component : pageLayoutHoc(Home),
    key: 'home'
  }
]
