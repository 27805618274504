import React, { Component } from 'react'
import { Grid, Container } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom'
import { Header, Footer, SideBar } from '../shared_elements';
import { imageBasePath } from '../constants';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({
            hasError: true
        })
    }

    // componentWillReceiveProps() {
    //     if (this.state.hasError) {
    //         return
    //     }
    // }

    render() {
        if (this.state.hasError) {
            return (
                <div className="errorBoundary flexCentered">
                    <Container fixed>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} className="textCenter">
                                <img src={imageBasePath + 'oops.jpg'} />
                                <h1 className="heading4">Oops..! Something went wrong</h1>
                                <h2 className="paragraph">An error occurred. Please try again later.</h2>
                                <Link to="/" onClick={() => this.setState({ hasError: false })} className="primaryBtn">Return to Home</Link>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            )
        } else {
            return this.props.children;
        }
    }
}
export default withRouter(ErrorBoundary)