import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { images } from '../constants';
import CloseIcon from '@material-ui/icons/Close';

function SearchBar(props) {
  const { text } = props;

  return (
    <div className='inputGroup searchWrapper'>
      <form onSubmit=''>
        <div className='search'>
          <InputBase
            placeholder={
              props.placeholder
                ? props.placeholder
                : 'Search or add tools and services...'
            }
            className='searchArea'
            inputProps={{ 'aria-label': 'search' }}
            value={props.text ? text : props.text}
            // autoFocus={props.mobileSearchFlag ? true : false}
            onChange={(e) => {
              props.handleSearch(e.target.value.trim());
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                props.handleSearch(text);
                e.target.blur();
              }
            }}
          />
          <div className='searchIcon' onClick=''>
            <img
              src={images.searchIcon}
              onClick={() => props.handleSearch(text)}
            />
          </div>
          {text && text.length ? (
            <CloseIcon
              style={{
                position: 'absolute',
                right: '40px',
                cursor: 'pointer',
                top: '10px',
                fontSize: '20px',
                color: '#778DA9',
              }}
              className='closeIcon'
              onClick={() => {
                props.handleSearch('');
              }}
            />
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default withRouter(SearchBar);
