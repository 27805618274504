import React from 'react';
import { Container, Grid, List, ListItem } from '@material-ui/core';
import {images} from '../constants';
function Footer() {
    return (
        <div className="footer">
            <Container maxWidth="lg">
                <Grid container direction="row" justify="center" alignItems="center" className="footerRow">
                    <Grid item md={6} sm={6} xs={12}>
                        <p className="copyright supportText">© 2021 Product10x, LLC. All Rights Reserved.</p>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ul className="listInline legalList ">
                            <li className="listInlineItem followus">
                                <div className="flexCentered">
                                    {/* <span className="supportText">Follow us on: </span> */}
                                    <ul className="listInline">
                                        <li  className="listInlineItem">
                                            <a href="https://www.facebook.com/product10x" rel='noopener noreferrer' target="_blank">
                                                <img src={images.facebookFooter} ale="Facebook icon" />
                                            </a>
                                        </li>
                                        <li  className="listInlineItem">
                                            <a href="https://www.linkedin.com/company/product10x" rel='noopener noreferrer' target="_blank">
                                                <img src={images.linkedinFooter} ale="Linkedin icon" />
                                            </a>
                                        </li>
                                        <li  className="listInlineItem">
                                            <a href="https://twitter.com/product10x" rel='noopener noreferrer' target="_blank">
                                                <img src={images.twitterFooter} ale="Twitter icon" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="listInlineItem">
                                <a href="https://app.termly.io/document/terms-of-use-for-website/bdacc6d0-0ad9-405f-ae62-cb82c8af5f95" rel='noopener noreferrer' target="_blank" className="supportText">Terms of Use</a>
                            </li>
                            <li className="listInlineItem">
                                <a href="https://app.termly.io/document/privacy-policy/50112a10-d0a0-44eb-b73c-a261d0986e8d" rel='noopener noreferrer' target="_blank" className="supportText">Privacy Policy</a>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer
