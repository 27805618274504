import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Footer } from '../shared_elements';
import { connect } from 'react-redux'
import {ToastMessage, UpdatePopup} from '../shared_elements';
import { globalGetService } from '../utils/globalApiServices';
/* eslint-disable */
export default function pageLayoutHoc(HocComponent, extraProps={}){
  class PageLayout extends Component{
    constructor(props) {
      super(props);
      this.state = {
      }
    }
    render(){
      return (
        <>
          <Header />
          <main>
            <div>
              <HocComponent
                {...this.props}
              />
            </div>
          </main>
          <Footer />
        </>
      )
    }
  }
  const mapStateToProps = state => ({
    toastLists: state.sharedReducers.toastLists,
  });
  return withRouter(connect(mapStateToProps, null)(PageLayout))
}
