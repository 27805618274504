import React, {useEffect} from 'react'
import styles from '../../../assets/styles/home.module.scss';
import { List, ListItem, Grid } from '@material-ui/core';
import { CustomCircularProgress } from '../../../shared_ui_components';
import { Success } from '.';

export default function SectionsListing(props){
    const { activeSection, sections, warning, setNextSectionIndex, setOpenWarning } = props;
    const scrollTop = () =>{
      window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const getProgress = (total_mandatory_answered, total_mandatory) => {
        let value = (total_mandatory_answered/total_mandatory) * 100;
        return isNaN(value) ? 0 : value ;
    }

    const getProgressClass = (total_mandatory_answered, total_mandatory) => {
        let value = (total_mandatory_answered/total_mandatory) * 100;
        if(value >= 100){
            return "successColor";
        }else if(value > 50 && value < 100 ){
            return 'warningColor';
        }else if(value > 0 && value < 50){
            return 'errorColor';
        }else{
            return null
        }
    }

    return(
        <Grid item xs={12} md={4}>
            <div className={"sectionsListingBlk"}>
                <List className={`listUnstyled`}>
                    {
                        sections ? sections.map((section, index) =>
                        <ListItem key={index} onClick={() => {
                            if(warning){
                                setOpenWarning(true);
                                setNextSectionIndex(index);
                            }else{
                                props.handleChangeSection(index);
                            }
                            scrollTop();
                        }} className={`${activeSection.slug === section.slug ? 'active' : ''}  ${getProgressClass(section.total_mandatory_answered, section.total_mandatory)}`}>
                            <CustomCircularProgress order={index+1} value={getProgress(section.total_mandatory_answered, section.total_mandatory)}/>
                            <h3 className={`sectionName heading4Medium`}>{section.title}</h3>
                            <span className={'line' + ' ' + `${activeSection.slug === section.slug ? 'activee' : ''}`}></span>
                        </ListItem>) : null
                    }
                </List>
            </div>
        </Grid>
    )
}
