import React from 'react'
import { SwipeableDrawer } from '@material-ui/core';
import { images } from '../constants';

export default function SectionListingMenu(props){
    const { open } = props
    return(
        <>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={open}
                        onClose={props.handleClose}
                        className="sectionListingMenu"
                    >
                        <div className={"sectionListingMenuWrapper"}>
                            <img src={images.menuClose} className="menuCloseIcon" onClick={props.handleClose}/>
                            {props.children}
                        </div>
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </>
    )
}