import React from 'react'
import { Dialog, DialogContent, DialogActions} from '@material-ui/core'
import CustomButton from './CustomButton';

export default function GoBackWarningDialog(props){
    return(
        <div className="customRadioButtonDialogWrapper GoBackWarningDialogWrapper">
            <Dialog maxWidth="sm" onClose={props.onClose} aria-labelledby="simple-dialog-title" className="customRadioButtonDialog GoBackWarningDialog" open={props.open}>
                <DialogContent >
                    <div className="contentBlk">
                      <h1 className="heading4Medium">Changes made may not be saved. Do you want to continue without saving?</h1>
                    </div>
                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={props.onClose} className="heading5Bold">
                        Cancel
                    </CustomButton>
                    <CustomButton onClick={props.handleChangeSection} className="primaryBtn">
                        Continue
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}
