import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog, DialogContent, DialogActions} from '@material-ui/core'
import { images } from '../constants';
import CustomButton from './CustomButton';
import { globalPostService } from '../utils/globalApiServices';
import { toastFlashMessage } from '../utils';

export default function SubmitFormDialog(props){
    let history = useHistory()
    const [loader, setLoader] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        globalPostService(`/product/assessment-submit/`)
        .then(response => {
            setLoader(false)
            if(response.statusCode == 200){
                toastFlashMessage(response.message, 'success')
                localStorage.removeItem('access')
                history.push({
                    pathname: `/result/${response.data.assessment_id}`,
                    state: { result: response.data }
                })
            }else{
                toastFlashMessage(response.message, 'error')
            }
        })
        .catch(err => {
            setLoader(false)
            toastFlashMessage(`Something went wrong, please try again!`, 'error')
        })
    }
    useEffect(() => {
        setLoader(false)
    }, [props.open])
    return(
        <div className="customRadioButtonDialogWrapper submitFormDialogWrapper">
            <Dialog maxWidth="sm" onClose={props.onClose} aria-labelledby="simple-dialog-title" className="customRadioButtonDialog submitFormDialog" open={props.open}>
                <DialogContent >
                    <div className="contentBlk">
                        <img src={images.alertIcon} alt="" className={`alertIcon`}/>
                        <h1 className="heading3Medium">Are you sure you want to Submit the form?</h1>
                        <p className="paragraph subText">Once submitted you can’t undo this action</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <CustomButton className={`secondaryBtn ${loader ? 'disabledBtn': ''}`} onClick={props.handleClose}>
                        Go Back
                    </CustomButton>
                    <CustomButton onClick={handleSubmit} className={`primaryBtn ${loader ? 'disabledBtn': ''}`}>
                        Submit
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}
