import React from 'react';
import { Route, Redirect, useLocation} from 'react-router-dom';
import queryString from 'query-string';

export default function PrivateRoute({ component: Component, path: path, ...rest }) {
  let location = useLocation();
  
  const isUserLoggedIn = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const access = JSON.parse(localStorage.getItem('access'))
    if (userInfo && access) {
      return true;
    } else {
      const querys = queryString.parse(location.search);
      const redirection= querys.redirection;
      if(redirection){
        localStorage.setItem('redirection', location.pathname);
      }
      return false;
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/register',
            }}
          />
        )
      }
    />
  );
}
