import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import {Introduction, SignUpForm} from '../components';
import {images, regExpression} from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { signUpApi } from '../apiServices';
import { withRouter } from 'react-router-dom';

class SignUp extends Component{
  constructor(props){
    super(props);
    this.state = {
      formData: {
        email: '',
        full_name: '',
        agree: false,
      },
      error: {},
      errorCode: {
        email: {
          0: '',
          1: 'Please enter email address',
          4: 'Invalid email address',
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email,
        },
        full_name: {
          0: '',
          1: 'Please enter full name',
        },
        fullNameObj: {
          requiredFlag: true,
        },
        agree: {
          0: '',
          1: 'Please accept terms and conditions to continue',
        },
        agreeObj: {
          requiredFlag: true,
        },
      },
      submitLoader: false,
    };
    this.signUpApi = signUpApi.bind(this);
  }
  componentDidMount(){
    if(localStorage.getItem('userInfo') && localStorage.getItem('access')){
      this.props.history.push('/')
    }
  }
  handleChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [key]: value
      },
      error:{
        ...prevState.error,
        [key]: ''
      }
    }));
  }

  handleSubmit = () => {
    const {errorCode, formData} = this.state;
    const validateNewInput = {
      email: errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: formData.email?.toLowerCase()})],
      full_name: errorCode['full_name'][fieldValidation({...errorCode['fullNameObj'], fieldval: formData.full_name})],
      agree: errorCode['agree'][fieldValidation({...errorCode['agreeObj'], fieldval: formData.agree})],
    }
    if(Object.keys(validateNewInput).every(k => validateNewInput[k] == '')){
      this.setState({
        submitLoader : true
      });
      this.signUpApi({formData: formData});
    }else{
      this.setState({
        error : validateNewInput
      })
    }

  }

  render(){
    const{formData, error, submitLoader } = this.state;

    return(
        <div className={styles.authPage}>
          <section className={styles.authSection + ' flexCentered'}>
            <Container maxWidth={"lg"} className={styles.authContainer}>
                <Grid container spacing={2} className={styles.logoRow}>
                  <Grid item xs={12} md={12}>
                    <img className={styles.logo} src={images.logoBlackSvg} alt="Product10x" />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={styles.authRow}>
                  <Grid item xs={12} md={6}>
                    <Introduction />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SignUpForm
                      formData={formData}
                      error={error}
                      handleChange={this.handleChange}
                      handleSubmit={this.handleSubmit}
                      submitLoader={submitLoader}
                    />
                  </Grid>
                </Grid>
            </Container>
          </section>
        </div>
    )
  }
}

export default withRouter(SignUp);
