import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PropTypes from 'prop-types';

export default function TextArea(props) {
  const { required } = props
  return (
    <div className='inputGroup textAreaGroup'>
      <label>{props.label}<span className="MuiFormLabel-asterisk">{required ? ' *' : ''}</span></label>
      <TextareaAutosize
        rowsMin={2}
        {...props}
        error={props.error ? true : false}
      />
      {
        props.error ?
        <h6 className="errorMsg">
          {props.error}
        </h6>
        :
        null
      }
    </div>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
};

TextArea.defaultProps = {
  label: '',
};
