import React from 'react';
import { Checkbox, withStyles, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
const PrimaryCheckbox = withStyles({
  root: {
    'color': '#17C3B2',
    '&$checked': {
      color: '#17C3B2',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

const SecondaryCheckbox = withStyles({
  root: {
    'color': '#FE7C81',
    '&$checked': {
      color: '#FE7C81',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export default function CustomCheckbox(props) {
  const {
    value,
    label,
    onChange,
    type = 'primary',
    isMarginBottomNeeded = true,
  } = props;
  return (
    <div
      className={`${isMarginBottomNeeded ? 'inputGroup' : ''} checkBoxGroup`}
    >
      <FormControlLabel
        value={value}
        control={
          type == 'secondary' ? (
            <SecondaryCheckbox
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          ) : (
            <PrimaryCheckbox
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          )
        }
        // label={<p className="paragraph" dangerouslySetInnerHTML={{__html: label}}></p>}
        label={label}
        labelPlacement='end'
      />
      {
        props.iserror ?
        <h6 className="errorMsg">
          {props.iserror}
        </h6>
        :
        null
      }
    </div>
  );
}

CustomCheckbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.element,
  ]),
  type: PropTypes.oneOf(['primary', 'secondary']),
  onChange: PropTypes.func,
};

CustomCheckbox.defaultProps = {
  value: true,
  label: '',
  type: 'primary',
  onChange: (e) => {
  },
};
