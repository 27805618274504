import React, { useState } from 'react';
import {List, ListItem} from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { images } from '../../../constants';
import { CustomButton, ExternalLink } from '../../../shared_ui_components';
import { globalPostService } from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';

export default function Introduction(props) {
  const { email, fullName } = props
  const [resendLoader, setResendLoader] = useState(false)

  const handleResendEmail = () => {
    setResendLoader(true)
    globalPostService(`/auth/register/`, { email: email, full_name: fullName })
    .then(response => {
      setResendLoader(false)
      if(response.statusCode == 200){
        toastFlashMessage(response.message, 'success');
      }else{
        toastFlashMessage(response.message, 'error');
      }
    })
  }
  return(
      <>
        <div className={styles.emailVerificationWrapper + ' textCenter'} >
            <h1 className="heading1">One step closer to knowing your Startup Mojo.</h1>
            <h2 className="heading4Medium">Please check your email to access the link to the tool</h2>
            <img className={styles.featuredImg} src={images.emailVerificationImg} alt="Image" />
            <h3 className="label">
              An email has been sent to <span>{email}</span> with a magic link. Click on the link to start filling the quiz to know your Startup Mojo<br/>
              If you have not received the email, please check your spam folder.
            </h3>
            {/* <div className={styles.mailBtnBlk}>
              <ExternalLink
                linkimg={images.gmailImg}
                href={`https://mail.google.com/mail/u/0/`}
                linktext='Open Gmail'
                className={styles.externalLinkBtn + ' secondaryBtn'}
              />
            </div>
            <p className={styles.label + ' label'}>
              or
            </p>
            <div className={styles.mailBtnBlk}>
              <ExternalLink
                linkimg={images.outlookImg}
                href={`https://outlook.live.com/mail/0/inbox`}
                linktext='Open Outlook'
                className={styles.externalLinkBtn + ' secondaryBtn'}
              />
            </div> */}
            <h4 className={`label ${resendLoader ? 'disabled': ''}`} onClick={handleResendEmail}>Still can’t find the email? <a>Resend Email</a></h4>
            <ExternalLink
              linkimg={images.helpIcon}
              href={`mailto:hello@product10x.com`}
              linktext=''
              className={styles.helpIcon}
            />
        </div>
      </>
  )
}
