import React, { useEffect, useState } from 'react';
import { Container, Grid, Hidden } from '@material-ui/core';
import { SectionsListing, SectionForm } from '../components'
import { useHistory, useParams } from "react-router-dom";
import styles from '../../../assets/styles/home.module.scss';
import { globalGetService } from '../../../utils/globalApiServices';
import { SubmitFormDialog, GoBackWarningDialog } from '../../../shared_ui_components';
import { SectionListingMenu } from '../../../shared_elements';

export default function Home(props){
  const [sections, setSections] = useState([])
  const [activeSection, setActiveSection] = useState({})
  const [loader, setLoader] = useState(true)
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false)
  const [warning, setWarning] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [nextSectionIndex, setNextSectionIndex] = useState('');
  const [activeIndex, setActiveIndex] = useState('')
  const [openMenu, setOpenMenu] = useState(false)

  let history = useHistory();
  let locationParams = useParams();

  const fetchSectionListApi = () => {
    globalGetService(`/product/sections/`)
    .then(response => {
      setLoader(false)
      if(response.statusCode == 200){
        if(response.data && response.data.length){
          setSections(response.data);
          if(locationParams && locationParams.formslug){
            let section = response.data.find(sec => sec.slug ===  locationParams.formslug);
            if(section){
              const index = response.data.findIndex(item => item.order === section.order)
              setActiveSection(section)
              setActiveIndex(index);
            }else{
              setActiveSection(response.data[0]);
              history.push(`/${response.data[0].slug}`);
              setActiveIndex(0)
            }
          }else{
            setActiveSection(response.data[0]);
            history.push(`/${response.data[0].slug}`);
            setActiveIndex(0)
          }
        }
      }
    })
  }
  useEffect(() => {
    if(activeSection.slug != locationParams.formslug && sections){
      let section = sections.find(sec => sec.slug ===  locationParams.formslug);
      if(section){
        const index = sections.findIndex(item => item.order === section.order)
        setActiveSection(section)
        setActiveIndex(index);
      }
    }
  }, [locationParams.formslug])
  useEffect(() => {
    fetchSectionListApi()
  }, [])
  const forceUpdateSectionList = (section) => {
    // const udpatedSections = sections.map(sec => sec.order == section.order ? section : sec)
    // setSections(udpatedSections)
    let udpatedSections = []
    globalGetService(`/product/sections/`)
    .then(response => {
      if(response.statusCode == 200){
        if(response.data && response.data.length){
          udpatedSections = response.data;
          setSections(udpatedSections)
          const submitFlag = udpatedSections.every(section => section.total_mandatory_answered >= section.total_mandatory)
          if(submitFlag){
            setOpenSubmitDialog(true)
          }
        }
      }
    })
  }
  // const handleChangeSection = (order) => {
  //   const section = sections.find(section => section.order == order)
  //   if(section){
  //     setActiveSection(section);
  //     history.push(`/${section.slug}`);
  //   }else{
  //     setActiveSection(sections[0])
  //     history.push(`/${sections[0].slug}`);
  //   }
  // }
  const handleChangeSection = (index) => {
    const section = sections[index]
    if(section){
      setActiveSection(section);
      setActiveIndex(index)
      history.push(`/${section.slug}`);
    }else{
      setActiveIndex(0)
      setActiveSection(sections[0])
      history.push(`/${sections[0].slug}`);
    }
    setOpenMenu(false)
  }
  window.addEventListener("beforeunload", function (e) {
    (e || window.event).returnValue = null;
    return null;
  });
  const handleClickMenu = () => {
    setOpenMenu(!openMenu)
  }
  return(
      <div className={styles.homePage}>
        <section className={styles.homeSection}>
            <Container className={styles.homeContainer}>
                {/* <PageTitleBlk activeSection={activeSection} handleSubmitSection={handleSubmitSection}/> */}
                <Grid container spacing={2} className={styles.assessmentBlk}>
                  <Hidden only={['xs', 'sm']}>
                    <SectionsListing 
                      handleChangeSection={handleChangeSection} 
                      activeSection={activeSection} 
                      sections={sections}
                      warning={warning}
                      setOpenWarning={setOpenWarning}
                      setNextSectionIndex={setNextSectionIndex}
                      activeIndex={activeIndex}
                    />
                  </Hidden>
                  <SectionForm 
                    section={activeSection}
                    handleChangeSection={handleChangeSection}
                    forceUpdateSectionList={forceUpdateSectionList}
                    warning={warning}
                    setWarning={setWarning}
                    setOpenWarning={setOpenWarning}
                    setNextSectionIndex={setNextSectionIndex}
                    activeIndex={activeIndex}
                    handleClickMenu={handleClickMenu}
                  />
                </Grid>
            </Container>
        </section>
        <SubmitFormDialog 
          open={openSubmitDialog}
          handleClose={() => setOpenSubmitDialog(false)}
        />
        <GoBackWarningDialog 
          open={openWarning} 
          onClose={() => setOpenWarning(false)} 
          handleChangeSection={() => {handleChangeSection(nextSectionIndex); setOpenWarning(false)}}
        />
        <SectionListingMenu 
            open={openMenu}
            handleClose={() => setOpenMenu(false)}
        >
          <SectionsListing 
            handleChangeSection={handleChangeSection} 
            activeSection={activeSection} 
            sections={sections}
            warning={warning}
            setOpenWarning={setOpenWarning}
            setNextSectionIndex={setNextSectionIndex}
            activeIndex={activeIndex}
          />
        </SectionListingMenu>
      </div>
  )
}