import React, { useState, useEffect, useRef } from "react";
import { List, ListItem } from '@material-ui/core';
import CustomButton from './CustomButton';
import InputText from './InputText';

export default function Counter(props) {
  const { value, handleDecrement, handleIncrement, minValue, maxValue, label, mandatory } = props
  
  return (
    <div className='inputGroup counterGroup'>
      {
          label ?  <label>{label}<span className="MuiFormLabel-asterisk">{mandatory ? ' *' : ''}</span></label> : null
      }
      <div className="counterWrapper">
        <List className="listInline flexCentered">
          <ListItem className="listInlineItem metricInput decrement">
            <CustomButton onClick={handleDecrement} className={`metricBtn ${value <= (minValue ? minValue : 0) ? 'disabled' : ''}`}> <span style={{fontSize: "24px"}}>-</span> </CustomButton>
          </ListItem>
          <ListItem className="listInlineItem count">
            <InputText
              value={value}
              onChange={props.onChange}
              placeholder={props.placeholder}
              type="number"
            />
          </ListItem>
          <ListItem className="listInlineItem metricInput incriment">
            {/* <CustomButton onClick={handleIncrement} className={`metricBtn ${value >= 15 ? 'disabled' : ''}`}> + </CustomButton> */}
            <CustomButton onClick={() => {handleIncrement(); window.scrollTo({top: window.pageYOffset+250,left: 100,behavior: 'smooth'})}} className={`metricBtn ${maxValue ? value >= maxValue ? 'disabled' : '' : ''}`}> <span style={{fontSize: "16px"}}>+</span> </CustomButton>
          </ListItem>
        </List>
      </div>
      {
         props.error ?
         <h6 className="errorMsg">
           {props.error}
         </h6>
         :
         null
      }
    </div>
  );
}
