import React from 'react';
import styles from '../../../assets/styles/auth.module.scss';
import { InputText, CustomCheckbox, ExternalLink, CustomButton} from '../../../shared_ui_components';

export default function SignUpForm(props) {
    const{formData, error, submitLoader} = props;
  return(
      <>
        <div className={styles.formWrapper} >
            <h2 className="heading3SemiBold">What are you waiting for?</h2>
            <InputText
              id='fullName'
              label='Full Name'
              placeholder='Enter your full name'
              value={formData.full_name ? formData.full_name : ''}
              onChange={(e) => props.handleChange('full_name', e.target.value)}
              error={error.full_name}
            />
            <InputText
              id='email'
              label='Email Address'
              placeholder='Enter your email address'
              value={formData.email ? formData.email : ''}
              onChange={(e) => props.handleChange('email', e.target.value)}
              error={error.email}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  props.handleSubmit()
                }
              }}
            />
            <CustomCheckbox
             value={formData.agree}
             // onChange={(e) => props.handleChange('agree', e.target.value)}
             onChange={(val) => props.handleChange('agree', val)}
             label={
               <p className={styles.agreeTitle + ' paragraph'}>
                 I agree to the
                 <ExternalLink
                   href={`https://app.termly.io/document/terms-of-use-for-website/bdacc6d0-0ad9-405f-ae62-cb82c8af5f95`}
                   linktext=' Terms of Use '
                   target={'_blank'}
                   className={styles.externalLink + ' paragraph'}
                 />
                 and
                 <ExternalLink
                   href={`https://app.termly.io/document/privacy-policy/50112a10-d0a0-44eb-b73c-a261d0986e8d`}
                   linktext=' Privacy Policy '
                   target={'_blank'}
                   className={styles.externalLink + ' paragraph'}
                 />
               </p>
             }
             iserror={error.agree}
           />
           <div className={styles.submitBlk}>
            <CustomButton children="Find out Startup Mojo" className={`primaryBtn submitBtn ${(submitLoader || Object.keys(error).some(key => error[key] != "")) ? ' disabledBtn' : null}`} onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleSubmit()}}/>
           </div>
        </div>
      </>
  )
}
