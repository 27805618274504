import React,  { useState } from 'react';
import { Grid, List, ListItem} from '@material-ui/core';
import {images} from '../../../constants';
import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/auth.module.scss';
import { Skeleton } from '@material-ui/lab';

export default function Shimmer(props) {
  // const [loader, setLoader] = useState(false)
  return(
    <>
      {
        props.type == 'Verification' &&
        <div className={styles.VerificationBlk}>
          <div className={styles.emailVerificationWrapper + ' textCenter'} >
              <h1 className="heading1"><Skeleton variant="text" height={50} width="30%" style={{display:'inline-block', margin:'0 auto'}}/></h1>
              <h2 className="heading4Medium"><Skeleton variant="text" height={30} width="60%" style={{display:'inline-block', margin:'0 auto'}}/></h2>
              <Skeleton variant="text" height={240} width={190} style={{display:'inline-block', margin:'0 auto'}}/>
              <h3 className="label">
                <Skeleton variant="text" height={30} width="100%" style={{display:'inline-block', margin:'0 auto'}}/>
              </h3>
              <div className={styles.mailBtnBlk}>
                <Skeleton variant="text" height={55} width={150} style={{display:'inline-block', margin:'0 auto'}}/>
              </div>
              <p className={styles.label + ' label'}>
                <Skeleton variant="text" height={30} width={25} style={{display:'inline-block', margin:'0 auto'}}/>
              </p>
              <div className={styles.mailBtnBlk}>
                <Skeleton variant="text" height={55} width={150} style={{display:'inline-block', margin:'0 auto'}}/>
              </div>
              <h4 className={`label}`}><Skeleton variant="text" height={30} width="50%" style={{display:'inline-block', margin:'0 auto'}}/></h4>
          </div>
        </div>
      }
    </>
  )
}
