//Images
import logoSvg from '../assets/img/logo.svg';
import logoPng from '../assets/img/logo.png';
import logoNew from '../assets/img/logo_new.png';
import logoBlackSvg from '../assets/img/logo_black.svg';
import logoBlackPng from '../assets/img/logo_black.png';
import backArrow from '../assets/img/back_arrow.svg';
import backArrowActive from '../assets/img/back_arrow_active.svg';
import closeIcon from '../assets/img/close.svg';
import checkedIcon from '../assets/img/checked_icon.svg';
import signupFeaturedImg from '../assets/img/signup_featured.png';
import signupFeaturedImgSvg from '../assets/img/signup_featured_small.svg';
import emailVerificationImg from '../assets/img/email_verification.png';
import gmailImg from '../assets/img/gmail.svg';
import outlookImg from '../assets/img/outlook.svg';
import toastsuccess from '../assets/img/success.svg';
import toastsuccessClose from '../assets/img/success_close.svg';
import toasterror from '../assets/img/error.svg';
import toasterrorClose from '../assets/img/error_close.svg';
import toastinfo from '../assets/img/info.svg';
import toastinfoClose from '../assets/img/info_close.svg';
import toastwarning from '../assets/img/warning.svg';
import toastwarningClose from '../assets/img/warning_close.svg';
import helpIcon from '../assets/img/help.svg';
import searchIcon from '../assets/img/search.svg';
import calederIcon from '../assets/img/calendar.svg';
import successBg from '../assets/img/success_bg.png';
import successFeatured from '../assets/img/success_featured.png';
import stepCompleted from '../assets/img/step_completed.svg';
import alertIcon from '../assets/img/alert_icon.svg';
import facebookFooter from '../assets/img/footer_facebook.svg';
import linkedinFooter from '../assets/img/footer_linkedin.svg';
import twitterFooter from '../assets/img/footer_twitter.svg';
import menuIcon from '../assets/img/menu_icon.svg';
import menuClose from '../assets/img/menu_close.svg';

export const images = {
  logoSvg: logoSvg,
  logoPng: logoPng,
  backArrow: backArrow,
  backArrowActive: backArrowActive,
  closeIcon: closeIcon,
  checkedIcon: checkedIcon,
  signupFeaturedImg: signupFeaturedImg,
  signupFeaturedImgSvg: signupFeaturedImgSvg,
  logoBlackSvg: logoBlackSvg,
  logoBlackPng: logoBlackPng,
  emailVerificationImg: emailVerificationImg,
  logoNew: logoNew,
  gmailImg: gmailImg,
  outlookImg: outlookImg,
  toastsuccess: toastsuccess,
  toastsuccessClose: toastsuccessClose,
  toasterror: toasterror,
  toasterrorClose: toasterrorClose,
  toastinfo: toastinfo,
  toastinfoClose: toastinfoClose,
  toastwarning: toastwarning,
  toastwarningClose: toastwarningClose,
  helpIcon: helpIcon,
  searchIcon: searchIcon,
  calederIcon: calederIcon,
  successBg: successBg,
  successFeatured: successFeatured,
  stepCompleted: stepCompleted,
  alertIcon: alertIcon,
  facebookFooter: facebookFooter,
  linkedinFooter: linkedinFooter,
  twitterFooter: twitterFooter,
  menuIcon,
  menuClose
}


export const imageBasePath = '';
export const regExpression = {
   email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // email: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/,
  url: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+([-]*)+[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  password: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
  //(?=.*?[A-Z])
  digits: /^(0|[1-9][0-9]{0,9})$/,
};

export const reactSelectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      'backgroundColor': isDisabled
        ? null
        : isSelected
        ? '#F4F2EF'
        : isFocused
        ? '#F4F2EF'
        : null,
      'color': isDisabled ? '#ccc' : isSelected ? '#0B6259' : '#352929',
      'cursor': isDisabled ? 'not-allowed' : 'pointer',
      'font-weight': '400',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#F3F3F3' : '#F3F3F3'),
      },
    };
  },
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    'background': isFocused ? '#FFFFFF' : null,
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    'boxShadow': 'none',
    'border': isFocused ? '1px solid #17C3B2' : '1px solid #E5E5E5',
    ':hover': {
      border: isFocused ? '1px solid #17C3B2' : '1px solid #E5E5E5',
    },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#F4F2EF',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#352929',
    fontSize: 14,
    fontWeight: 400,
    padding: '0px 10px 0px 10px',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    'color': '#00D2AD',
    ':hover': {
      backgroundColor: '#352929',
      color: 'white',
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    minHeight: '32px',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: '0 8px',
    color: '#352929',
    background: '#FBFBFB',
    height: '38px',
    borderRadius: '4px',
  }),
};
