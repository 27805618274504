import React, {useState} from 'react';
import flags from 'react-phone-number-input/flags'
import PhoneInput, { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function PhoneNumber(props) {
  const { required, error } = props
  return(
    <div className="inputGroup phoneNumberGroup">
      <label>Phone Number <span className="MuiFormLabel-asterisk">{required ? ' *' : ''}</span></label>
      <PhoneInput
        {...props}
        />
      { props.error ? <h6 className="errorMsg"> {props.error} </h6> : null }
    </div>
  )
}
