import { useCallback, useRef, useState, useEffect } from "react";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default function GoogleSearchSuggestion(input) {
  const [predictions, setPredictions] = useState([]);
  const autocomplete = useRef();

  if (!autocomplete.current) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  function getPlacePredictions(input) {
    let options = {
      componentRestrictions: { country: 'us' },
      strictBounds: true,
      types: ['address'],
      input,
    };

 
    
    autocomplete.current.getPlacePredictions(
      options ,
      (predictions) => {
        setPredictions(predictions);
      }
    );
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    if (input) {
      getPlacePredictions(input);
    }
  }, [input]);

  useEffect(() => {
    if (input) {
      debouncedGetPlacePredictions(input);
    }
  }, [input, debouncedGetPlacePredictions]);

  return predictions;
}
