import React from 'react'
import { Grid, List, ListItem } from '@material-ui/core'
import styles from '../../../assets/styles/home.module.scss';
import { Counter, MetricInput, InputText } from '../../../shared_ui_components';

export default function NumberChange(props){
    const { section } = props
    const handleIncrement = () => {
        let ans = (section.answer === "" ?  0 : parseInt(section.answer)) + 1;
        props.handleChange(section.id, ans ? ans : 0);
    };

    const handleDecrement = () => {
        let ans = (section.answer === "" ?  0 : parseInt(section.answer)) - 1;
        props.handleChange(section.id, ans ? ans : 0);
    };

    const handleChange = (e) => {
        props.handleChange(section.id, e.target.value);
    }
    
    return(
        <>
            <Counter
                value={section.answer}
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                onChange={handleChange}
                placeholder={section.placeholder}
                error={section.error}
                label={section.title}
                mandatory={section.mandatory}
            />
        </>
    )
}
