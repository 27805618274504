import React,  { useState } from 'react';
import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/home.module.scss';
import { Skeleton } from '@material-ui/lab';

export default function Shimmer(props) {
  // const [loader, setLoader] = useState(false)
  return(
    <>
      {
        props.type == 'Form' &&
        <div className={styles.formShimmer}>
          <Grid container spacing={2} className={styles.subSectionWrapper}>
            <Grid item xs={12}>
              <h3 className={`heading4Medium ${styles.subSectionTitle}`}><Skeleton variant="text" width={185} height={40} style={{display:'inline-block'}}/></h3>
            </Grid>
              {[0,1].map(item =>
                <>
                <Grid item xs={12} key={item}>
                   <div className="inputGroup">
                      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                        <label className="MuiFormLabel-root" style={{marginBottom:'0'}}>
                          <Skeleton variant="text" height={26} width={250} style={{display:'inline-block'}}/>
                        </label>
                        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                          <Skeleton variant="text" height={50} width="100%"/>
                        </div>
                      </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="inputGroup">
                     <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                       <label className="MuiFormLabel-root" style={{marginBottom:'0'}}>
                         <Skeleton variant="text" height={26} width={250} style={{display:'inline-block'}}/>
                       </label>
                       <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                         <Skeleton variant="text" height={50} width="100%"/>
                       </div>
                     </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                   <div className="inputGroup">
                      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                        <label className="MuiFormLabel-root" style={{marginBottom:'0'}}>
                          <Skeleton variant="text" height={26} width={250} style={{display:'inline-block'}}/>
                        </label>
                        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                          <Skeleton variant="text" height={50} width="100%"/>
                        </div>
                      </div>
                  </div>
                </Grid>
                </>
              )}
          </Grid>
        </div>
      }
    </>
  )
}
