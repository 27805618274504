import React from 'react';
import { Slider, Grid } from '@material-ui/core';
import MetricInput from './MetricInput';
import NumberFormat from 'react-number-format';

export default function ProgressSlider(props) {
  const { question, label, required, value, showlimit } = props

  return (
    <>
    {label ? <label>{label}<span className="MuiFormLabel-asterisk">{props.required ? ' *' : ''}</span></label>: null}
    <Grid container spacing={4} justify="center" alignItems="center" className="progressSliderWrapper flexCentered">
      <Grid item xs={8}>
          {
            question.show_limit == true ?
            <p className="sliderLabel">
              <span className="label secondaryLabel minValue hidden"><NumberFormat value={question.min_value ? question.min_value : '0' } displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              <span className="label secondaryLabel maxValue hidden"><NumberFormat value={question.max_value} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              {
                question.min_value_text ?
                <span className="label secondaryLabel minValue">{question.min_value_text}</span>
                :
                <span className="label secondaryLabel minValue"><NumberFormat value={question.min_value ? question.min_value : '0' } displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              }
              {
                question.max_value_text ?
                <span className="label secondaryLabel maxValue">{question.max_value_text  ? question.max_value_text : ''}</span>
                :
                <span className="label secondaryLabel maxValue"><NumberFormat value={question.max_value} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              }
            </p>
            :
            ''
          }
          <Slider step={10} max={question.max_value} value={value} onChange={(e, newValue) => props.handleChange(newValue)} aria-labelledby="input-slider" valueLabelDisplay="off"/>
      </Grid>
      <Grid item xs={4}>
          <MetricInput
            unitPosition={question.unit_position}
            unit={question.unit}
            value={value}
            handleChange={(e) =>  props.handleChange(e.target.value)}
            handleClickAway={(e) => {
              if(e.target.value < 0) {
                props.handleChange(0)
              }else if(parseFloat(e.target.value.replace(/,/g, '')) > question.max_value){
                props.handleChange(question.max_value)
              }
            }}
            max={question.max_value}
          />
      </Grid>
    </Grid>
    { props.error ?
        <h6 className="errorMsg">
          {props.error}
        </h6>
        :
        null}
    </>
  );
}
