import {useEffect,useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const overContent = [
    {
        title : 'What is Startup Mojo?',
        description: 'The Startup Mojo from Product10x is a startup health index tool that looks into every aspect of startup lifecycle and provides an overall score.',
        list: []
    },
    {
        title : 'Why use Startup Mojo?',
        description: '',
        list: [
            'Understand your startup strengths',
            'Identify Focus Areas',
            'Realize what needs to be done to scale'
        ]
    },
    {
        title : 'What’s the outcome?',
        description: 'Product10x will send you a pdf report to your registered email address that includes overall score and all individual startup stage score (such as problem definition, market opportunity, and product).',
        list: []
    }
]

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className="modalTitle heading2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton+' '+ 'closeButton'} onClick={onClose}>
          <span className="modalClose">Close</span>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
}))(MuiDialogActions); 

export default function OverviewModal() {


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const modalStatus = localStorage.getItem('onboardStatus');
  const userAccess = localStorage.getItem('access');

  useEffect(() => {

    

    if(userAccess && userAccess?.length !==0){
      if(!modalStatus){
        setTimeout(()=>{
          setOpen(true);
        },[3000])
        
      }
    }
      
  }, [modalStatus,userAccess]);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('onboardStatus', true);
  };

  return (
    <div>
      <Dialog className="overviewModal" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle className="titleBlock" id="customized-dialog-title" onClose={handleClose}>
            Overview
        </DialogTitle>
        <DialogContent dividers className="modalBody">
        {
            overContent.map((content, index) => (
                <div key={index} className="item">
                    <h2>{content.title}</h2>
                    <div className="description">
                        {
                            content.description ? 
                            <p className="paragraph">{content.description}</p>
                            : null
                        }

                        {
                            content.list && content.list.length ?
                            <ul>
                                {
                                    content.list.map((data, key) => (
                                        <li className="paragraph">{data}</li>
                                    ))
                                }
                            </ul>
                            : null
                        }
                    </div>
                </div>
            ))
        }
        </DialogContent>
        <DialogActions className="modalFooter">
            <p className="supportText"><span>Still have more questions? Write to us</span> <a href="mailto:hello@product10x.com">hello@product10x.com</a></p>
        </DialogActions>
      </Dialog>
    </div>
  );
}