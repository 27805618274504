import React from 'react';
import { Grid, Slider, List, ListItem, TextField } from '@material-ui/core';
import InputText from './InputText';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
export default function MetricInput(props) {
  return (
    <div className="inputGroup">
      {props.label ? <label>{props.label}<span className="MuiFormLabel-asterisk">{props.required ? ' *' : ''}</span></label> : null}
      <div className="rangeInputBlk">
        <List className="listInline">
          {
            props.unitPosition == 'left' ?
            <ListItem className={`${props.unitPosition} listInlineItem currencyItem `}>
              <span className="currency">{props.unit}</span>
            </ListItem>
            :
            null
          }
          <ListItem className="listInlineItem inputItem">
            {/* <InputText 
              // type="number" 
              value={props.value ? props.value : props.placeholder } 
              onChange={props.handleChange} 
              onBlur={props.handleClickAway} 
              placeholder={props.placeholder} 
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            /> */}
            <TextField
              placeholder={props.placeholder}
              value={props.value} 
              onChange={props.handleChange} 
              onBlur={props.handleClickAway} 
              placeholder={props.placeholder} 
              InputProps={props.unit === '$' ? {
                inputComponent: NumberFormatCustom,
              }: {type: 'number'}}
            />
          </ListItem>
          {
            props.unitPosition == 'right' || props.unitPosition == 'padded_right' ?
            <ListItem className={`${props.unitPosition} listInlineItem currencyItem `}>
              <span className="currency">{props.unit}</span>
            </ListItem>
            :
            null
          }
        </List>
      </div>
      {
        props.error ?
        <h6 className="errorMsg">
          {props.error}
        </h6>
        :
        null
      }   
    </div>
  );
}


MetricInput.propTypes = {
  unit_position: PropTypes.string,
};

MetricInput.defaultProps = {
  unit_position: 'left',
};
