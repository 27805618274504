import React, { useState, useEffect } from 'react';
import { Container, Grid, List, ListItem, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { images } from '../constants';
// import { ProfileDropdown } from '../shared_elements';

function Header(props) {
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    if(localStorage.getItem('userInfo')){
      setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
    }else{
      // props.history.push('/register')
    }
  }, [])
  return (
    <>
      <header className='primaryHeader'>
        <div className='headerWrapper'>
          <Container maxWidth="lg">
            <Grid container direction="row" justify="center" alignItems="center" className="headerRow">
              <Grid item md={4} sm={4} xs={5}>
                <Link to="/" className="logo">
                  <img src={images.logoNew} alt="Product10x" />
                </Link>
              </Grid>
              <Grid item md={8} sm={8} xs={7}>
                <List className="listInline textRight">
                  {userInfo.full_name ? <ListItem className="listInlineItem">
                    <span>{userInfo.full_name}</span>
                  </ListItem> : null}
                  <Hidden only={['xs', 'sm']}>
                    <ListItem className="listInlineItem">
                      <span>{userInfo.email}</span>
                    </ListItem>
                  </Hidden>
                </List>
              </Grid>
            </Grid>
          </Container>
        </div>
      </header>
      <div className="emptyHeaderdiv"></div>
    </>
  );
}

export default withRouter(Header);
