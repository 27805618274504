import { globalPostService } from '../../utils/globalApiServices'
import { toastFlashMessage } from '../../utils'

export function signUpApi(query={}){
    globalPostService(`/auth/register/`, query.formData)
    .then(response => {
        if(response.statusCode == 200){
            // this.setState((prevState) => ({
            //     ...prevState,
            //     submitLoader: false,
            //     formData: {
            //       email: '',
            //       full_name: '',
            //       agree: false
            //     }
            // }));
            toastFlashMessage(response.message, 'success');
            this.props.history.push({
              pathname: '/verifyemail',
              search: '',
              state: { formData: { email: query.formData.email, full_name: query.formData.full_name } },
            });
        }else{
            this.setState((prevState) => ({
                ...prevState,
                submitLoader: false,
            }));
            toastFlashMessage(response.message, 'error');
        }
    })
}

export function emailVerificationApi(query={}){
  globalPostService(`/auth/verify-email/`, query)
  .then(response => {
    if(response.statusCode == 200){
      this.setState({
        loader: false,
        emailVerified: true,
        errorMessage: response.message,
      });
      toastFlashMessage(response.message, 'success');
      localStorage.setItem('access', JSON.stringify(response.data.access))
      localStorage.setItem('userInfo', JSON.stringify(response.data.user))
      this.props.history.push('/')
    }else{
      this.setState({
        loader: false,
        emailVerified: false,
        errorMessage: response.message,
      });
      toastFlashMessage(response.message, 'error');
      this.props.history.push('/register');
    }
  })
}