import React, {Component} from 'react';
import {List, ListItem} from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { images } from '../../../constants';

export default function Introduction(props) {
  return(
      <>
        <div className={styles.introductionWrapper} >
            <h1 className="heading1">Startup Mojo</h1>
            <p className="heading4Medium">Want to know your Startup Mojo? Take this 15 minutes quiz to find out your startup's strengths and areas of focus</p>
            <List className="listUnstyled">
              <ListItem className={styles.listItem + ' heading4Medium'}>
                <span>It is absoulety FREE !!!</span>
              </ListItem>
              <ListItem className={styles.listItem + ' heading4Medium'}>
                <span>Magic link to access quiz</span>
              </ListItem>
              <ListItem className={styles.listItem + ' heading4Medium'}>
                <span>Understand your strengths</span>
              </ListItem>
              <ListItem className={styles.listItem + ' heading4Medium'}>
                <span>Identify focus areas</span>
              </ListItem>
              <ListItem className={styles.listItem + ' heading4Medium'}>
                <span>Act on how to scale</span>
              </ListItem>
            </List>
            {/* <img className={styles.featuredImg} src={images.signupFeaturedImgSvg} alt="Image" /> */}
        </div>
      </>
  )
}
