import SignUp from './containers/SignUp';
import EmailVerification from './containers/EmailVerification';
export const authRoutes = [
  {
    path: '/register',
    component : SignUp,
    key: 'register'
  },
  {
    path: '/verifyemail',
    component : EmailVerification,
    key: 'verifyemail'
  },
  {
    path: '/verify',
    component : EmailVerification,
    key: 'verify'
  },
]
