import React, { useState } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import './assets/styles/app.scss';
import configureStore from './store/configureStore';
import { homeRoutes } from './features/home/homeRoutes';
import { authRoutes } from './features/auth/authRoutes';
import { resultRoutes } from './features/result/resultRoutes';
import { policyRoutes } from './features/policy/policyRoutes';
import ErrorBoundary from './utils/ErrorBoundary';
import { ToastMessage } from './shared_ui_components';
import PrivateRoute from './hocs/PrivateRoute';
import {OverviewModal} from './shared_elements'

// import Page404 from './shared_elements/Page404';
const store = configureStore();

function App() {
  const [toastLists, setToastList] = useState(
    store.getState().sharedReducers.toastLists
  );
  store.subscribe(() => {
    setToastList(store.getState().sharedReducers.toastLists);
  });
  return (
    <div className=''>
      <Provider store={store}>
        <Router>
          <ErrorBoundary>
            <Switch>
              {[...authRoutes, ...resultRoutes, ...policyRoutes].map(({ path, component, key }, index) => (
                <Route exact path={path} component={component} key={key} />
              ))}
              {homeRoutes.map(({ path, component, key }, index) => (
                <PrivateRoute exact path={path} component={component} key={key} />
              ))}
              {
                /* {<Route path='*' exact={true} component={Page404} />}*/
              }
            </Switch>
          </ErrorBoundary>
        </Router>
        {toastLists && toastLists.length !== 0 ? (
        <ToastMessage
          type={toastLists[0].toastType}
          message={toastLists[0].message}
        />
      ) : null}
        <OverviewModal />
      </Provider>
    </div>
  );
}

export default App;
