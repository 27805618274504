import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styles from '../../../assets/styles/result.module.scss';
import { Grid } from '@material-ui/core';
import { SuccessProgress } from '.'
import { images } from '../../../constants';
import { CustomButton, CustomLink } from '../../../shared_ui_components';
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { toastFlashMessage, downloadFileType } from '../../../utils';
import { isMobile } from 'react-device-detect';

export default function AssessmentResult(props){
    const [result, setResult] = useState('')
    const [loader, setLoader] = useState(true)
    let params = useParams()

    const fetchResultApi = (id) => {
      globalGetService(`/result/assessment/${id}/`)
      .then(response => {
        if(response.statusCode == 200){
          setLoader(false)
          setResult(response.data)
        }else if(response.message){
          toastFlashMessage(response.message, 'error')
        }
      })
    }

    useEffect(() => {
      if(props.location.state && props.location.state.result){
        setResult(props.location.state.result)
        setLoader(false)
      }else{
        // fetchResultApi(params.assessmentId)
        setLoader(false)
      }
    }, [])

    const downloadReport = (e) => {
      e.preventDefault();
      globalExportService(`/result/download-assessment/${params.assessmentId}/`)
      .then(response => {
        if(response){
          downloadFileType(response, 'Report-'+ `${params.assessmentId}` , '.pdf')
        }
      })
    }
    return(
        <div className={styles.successWrapper}>
            <Grid container alignItems="center" justify="center" style={{height: isMobile ? window.innerHeight - 100 : window.innerHeight - 72}}>
                <Grid item xs={12}>
                  {loader ? 'Loading...' : <div className={styles.successContentBlk + ' textCenter'}>
                      <img className={styles.success} src={images.successBg} alt="Image" style={{width:'100%',margin:'0px'}}/>
                      <div className={styles.infoContent}>
                          <h3 className={`heading3SemiBold ${styles.heading3SemiBold}`}>Thank you for participating in Product10X index!</h3>
                          <p className={`heading3Medium ${styles.heading3Medium}`}>You have scored </p>
                          <SuccessProgress value={result.total_score}/>
                          <h4 className={`heading4Medium ${styles.heading4Medium}`}>Our team will reach out to you soon... We look forward to help you in accelerating your Startup!</h4>
                          <p className={`paragraph ${styles.paragraph}`}>You can download your submitted form here.</p>
                          <p className={styles.primaryBtn}>
                            <CustomButton children="Download Report" className={`primaryBtn`} onClick={downloadReport}/>
                          </p>
                          {/* <h4>
                            <CustomLink linktext="Go to Home" className={`backBtn`} linkimg={images.backArrow} onClick={(e) => {e.preventDefault(); e.stopPropagation();}}/>
                          </h4> */}
                      </div>
                  </div>}
                </Grid>
            </Grid>
        </div>
    )
}
