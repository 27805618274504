import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { images } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ToastMessage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const toastImg = props.type? props.type : null
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        style={{ zIndex: '999999', marginTop: '0' }}
        autoHideDuration={props.delay}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className='snackbar'
      >
        <div className={props.type}>
          <p className={props.type + ' paragraph'}>
            <img className="toastIcon" src={ images[`${'toast'+props.type}`]} />
            {props.message}
            <img className="closeIcon" src={ images[`${'toast'+props.type+'Close'}`]} onClick={() => handleClose()}/>
          </p>
        </div>
      </Snackbar>
    </div>
  );
}

ToastMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  message: PropTypes.string,
  delay: PropTypes.number,
};

ToastMessage.defaultProps = {
  type: 'success',
  message: '',
  delay: 7000,
};
