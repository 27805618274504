import React from 'react'
import { Grid, List, ListItem } from '@material-ui/core'
import styles from '../../../assets/styles/home.module.scss';
import { Counter, MetricInput, InputText } from '../../../shared_ui_components';

export default function SectionCounter(props){
    const { section } = props

    const handleIncrement = () => {
        // setCount(prevCount => prevCount + 1);
        const obj = {}
        section.headers.forEach(header => {
            obj[header.key] = ''
        })
        const updatedAnswer = [...section.answer, {...obj, order: section.answer.length + 1}]
        props.handleChange(section.id, updatedAnswer)
    };

    const handleDecrement = () => {
        section.answer.splice(- 1, 1)
        props.handleChange(section.id, section.answer)
    };
    const handleChange = (key, value, order) => {
        let updatedAnswerObj = section.answer.find(ans => ans.order == order)
        updatedAnswerObj[key] = value
        const updatedAnswer = section.answer.map(ans => ans.order == order ? updatedAnswerObj : ans)
        props.handleChange(section.id, updatedAnswer)
    }
    const getComponent = (header, answerObj) => {
        switch(header.input_type){
            case 'repeater':
                return <div className="orderWrapper">
                    <p className="paragraph">{answerObj[header.key]}</p>
                </div>
            case 'number':
                return <MetricInput
                    unitPosition={header.unit_position}
                    unit={header.unit}
                    value={answerObj[header.key]}
                    handleChange={(e) => handleChange(header.key, e.target.value, answerObj.order)}
                />
            case 'textfield':
                return <InputText
                    value={answerObj[header.key]}
                    placeholder={header.placeholder}
                    onChange={(e) => handleChange(header.key, e.target.value, answerObj.order)}
                />
        }
    }
    return(
        <Grid container className={styles.sectionCounter}>
            <Grid item xs={6}>
                <Counter
                    value={section.answer.length}
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    minValue={section.min_value}
                    maxValue={section.max_value}
                    label={section.title}
                    mandatory={section.mandatory}
                />
            </Grid>
            <Grid item xs={12}>
                <List className="listUnstyled">
                    <ListItem className={styles.headerRow}>
                        <Grid container spacing={2}>
                            {section.headers.map(((header, index) => <Grid item xs={header.grid}>
                                <label>{header.title}{header.mandatory ? <span className="MuiFormLabel-asterisk"> *</span> : ''}</label>
                            </Grid>))}
                        </Grid>
                    </ListItem>
                    {
                        section.answer.map(((answerObj, index)=> <ListItem className={styles.contentRow}>
                        <Grid container spacing={2}>
                            {section.headers.map((header, index) => <Grid item xs={header.grid}>
                                {getComponent(header, answerObj)}
                            </Grid>)}
                        </Grid></ListItem>))
                    }
                    {
                        section.error ? <ListItem>
                            <h6 className="errorMsg">
                                {section.error}
                            </h6>
                        </ListItem> : null
                    }                                            
                </List>
            </Grid>
        </Grid>
    )
}
