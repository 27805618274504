import axios from 'axios';
import { toastFlashMessage } from '../utils';
var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = 'https://api.mojo.product10x.com/';
axiosInstance.interceptors.request.use(function (config) {
  if (localStorage.getItem('userInfo')) {
    config['headers']['Authorization'] = JSON.parse(localStorage.getItem('access'));
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(function (response) {
  if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
    return response.data;
  } else if ((response.data.statusCode >= 1001 && response.data.statusCode <= 1004)) {
    localStorage.clear();
    window.location.href = '/';
    toastFlashMessage(response.data.message, 'error');
    return response.data;
  } else if (response.data.statusCode == 3001 || response.data.statusCode == '5001') {
    return response.data;
  }
  else {
    return response.data;
  }
}, function (error) {
  return Promise.reject(error)
})

export default axiosInstance
