import PrivacyPolicy from './containers/PrivacyPolicy';
import CookiePolicy from './containers/CookiePolicy';
import TermsAndCondition from './containers/TermsAndCondition';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const policyRoutes = [
  {
    path: '/privacy-policy',
    component : pageLayoutHoc(PrivacyPolicy),
    key: 'privacy-policy'
  },
  // {
  //   path: '/cookiepolicy',
  //   component : pageLayoutHoc(CookiePolicy),
  //   key: 'cookiepolicy'
  // },
  // {
  //   path: '/terms-of-service',
  //   component : pageLayoutHoc(TermsAndCondition),
  //   key: 'terms-of-service'
  // }
]
