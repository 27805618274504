import React, {useEffect, useState} from 'react';
import { Slider, Grid } from '@material-ui/core';
import MetricInput from './MetricInput';

export default function RangeSlider(props) {
    const { question, label, required } = props;
    const [value, setValue] = useState(props.value);
    const handleChange = (e, val) => {
        setValue(val);
    }
    const [error, setError] = useState({});

    const handleError = (ans) => {
        if(ans.type === 'from' && ans.val && !question?.answer?.to){
            setError({
                type : 'to',
                errorMsg: 'Please enter To'
            });
        }else if(ans.type === 'to' && ans.val && !question?.answer?.from){
            setError({
                type : 'from',
                errorMsg: 'Please enter From'
            });
        }else{
            setError({});
        }
    }

    useEffect(() => {
        if(question.error === 'required error'){
            if(question?.answer?.from && !question?.answer?.to){
                setError({
                    type : 'to',
                    errorMsg: 'Please enter To'
                });
            }else if(question?.answer?.to && !question?.answer?.from){
                setError({
                    type : 'from',
                    errorMsg: 'Please enter From'
                });
            }else{
                setError({});
            }
        }else{
            setError({});
        }
    }, [question.error])

    return (
        <>
        {label ? <label>{label}<span className="MuiFormLabel-asterisk">{props.required ? ' *' : ''}</span></label>: null}
        <Grid container spacing={0} justify="center" alignItems="center" className="progressSliderWrapper rangeSlider flexCentered">
            {/* <Grid item xs={12}>
                <Slider 
                    step={1000} 
                    min={question.min_value} 
                    max={question.max_value} 
                    value={value ? value : [question.min_value, question.min_value]} 
                    onChange={(e, newValue) => handleChange(e, newValue)} 
                    aria-labelledby="input-slider" 
                    valueLabelDisplay="off"
                    onChangeCommitted={(e, val) => props.handleChange({from : val[0], to : val[1]})}
                />
            </Grid> */}
            <Grid container spacing={4} justify="space-between" alignItems="center" className="progressSliderWrapper flexCentered">
                <Grid item xs={4}>
                    <lable className="label secondaryLabel">{question.headers[0].title}</lable>
                    <MetricInput 
                        unitPosition={question.headers[0].unit_position}
                        unit={question.headers[0].unit}
                        value={question.answer.from}
                        handleChange={(e) => props.handleChange({from : e.target.value, to : question.answer.to})}
                        handleClickAway={(e) => {
                            if(parseFloat(e.target.value.replace(/,/g, '')) > question.answer.to && question.answer.to){
                                props.handleChange({from: parseFloat(e.target.value.replace(/,/g, '')), to: parseFloat(e.target.value.replace(/,/g, ''))})
                            }else if(parseFloat(e.target.value.replace(/,/g, '')) < question.min_value) {
                                props.handleChange({from : question.min_value, to : question.answer.to})
                            }else if(parseFloat(e.target.value.replace(/,/g, '')) > question.max_value){
                                props.handleChange({from : question.max_value, to : question.answer.to})
                            }else if(!e.target.value){
                                props.handleChange({from: null, to: question.answer?.to})
                            }
                            handleError({type: 'from', val: e.target.value})
                        }}
                        min={question.min_value}
                        max={question.max_value}
                        placeholder={question.headers[0].placeholder}
                        error={error.type === 'from' ? error.errorMsg : ''}
                    />
                </Grid>
                <Grid md-offset-4 item xs={4}>
                    <lable className="label secondaryLabel">{question.headers[1].title}</lable>
                    <MetricInput 
                        unitPosition={question.headers[1].unit_position}
                        unit={question.headers[1].unit}
                        value={question.answer.to}
                        handleChange={(e) => props.handleChange({from : question.answer?.from , to : e.target.value})}
                        handleClickAway={(e) => {
                            if(parseFloat(e.target.value.replace(/,/g, '')) < question.answer?.from){
                                props.handleChange({from: question.answer?.from, to: question.answer?.from})
                            }else if(parseFloat(e.target.value.replace(/,/g, '')) < question.min_value) {
                                props.handleChange({from : question.answer?.from, to : question.min_value})
                            }else if(parseFloat(e.target.value.replace(/,/g, '')) > question.max_value){
                                props.handleChange({from : question.answer?.from, to : question.max_value})
                            }else if(!e.target.value){
                                props.handleChange({from: question.answer?.from, to: null})
                            }
                            handleError({type: 'to', val: e.target.value})
                        }}
                        min={question.min_value}
                        max={question.max_value}
                        placeholder={question.headers[1].placeholder}
                        error={error.type === 'to' ? error.errorMsg : ''}
                    />
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}
