import React from 'react'
import { Grid, List, ListItem } from '@material-ui/core'
import styles from '../../../assets/styles/home.module.scss';
import { InputText } from '../../../shared_ui_components';
import { images } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';

export default function KeyValuePair(props){
    const { section, label, required } = props

    const handleAddNew = () => {
        const obj = {}
        section.headers.forEach(header => {
            obj[header.key] = ''
        })
        const updatedAnswer = [...section.answer, {...obj, order: section.answer.length + 1}]
        props.handleChange(section.id, updatedAnswer)
    }
    const handleRemove = (order) => {
        const ansTobeRemoved = section.answer.find(ans => ans.order == order)
        const index = section.answer.indexOf(ansTobeRemoved)
        section.answer.splice(index, 1)
        const updatedAnswer = section.answer.map((ans, index) => {return {...ans, order: index + 1}})
        props.handleChange(section.id, updatedAnswer)
    }
    const handleChange = (key, value, order) => {
        let updatedAnswerObj = section.answer.find(ans => ans.order == order)
        updatedAnswerObj[key] = value
        updatedAnswerObj['error'] = ''
        const updatedAnswer = section.answer.map(ans => ans.order == order ? updatedAnswerObj : ans)
        props.handleChange(section.id, updatedAnswer)
    }
    const handleError = (key, value, order) => {
        let updatedAnswerObj = section.answer.find(ans => ans.order == order)
        let header = section.headers.find(header => header.key == key)
        if(header && header.err_msg && header.err_obj){
            updatedAnswerObj = {
                ...updatedAnswerObj,
                error: header['err_msg'][fieldValidation({...header.err_obj, fieldval: value, requiredFlag: false})]
            }
            const updatedAnswer = section.answer.map(ans => ans.order == order ? updatedAnswerObj : ans)
            props.handleChange(section.id, updatedAnswer)
        }
    }
    return(
        <List className={styles.keyValuePairBlk}>
            <label>{`${label}`} <span className="MuiFormLabel-asterisk" style={{float:'none'}}>{required ? '*' : ''}</span> <span className="heading5Bold" onClick={handleAddNew}>+ Add {section.value}</span></label>
            {
                section.answer.map((answerObj, index) => <ListItem key={index}>
                    <Grid container spacing={2}>
                        {section.headers.map((header, index) => <Grid item xs={header.grid} key={index}>
                            <InputText
                                value={answerObj[header.key]}
                                placeholder={header.placeholder}
                                onChange={(e) => handleChange(header.key, e.target.value, answerObj.order)}
                                type={header.input_type}
                                onBlur={(e) => handleError(header.key, e.target.value, answerObj.order)}
                                error={answerObj.error}
                            />
                        </Grid>)}
                    </Grid>
                    {answerObj.order > 1 ? <p className={`heading5Bold ${styles.removeBtn}`} onClick={() => handleRemove(answerObj.order)}>
                        <img src={images.closeIcon} />
                        <span>Remove {section.value}</span>
                    </p>: null}
                </ListItem>)
            }
            {   section.error ? <h6 className="errorMsg"> {section.error} </h6> : null }
        </List>
    )
}
