import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, List, ListItem, DialogActions } from '@material-ui/core'
import CustomRadioButton from './CustomRadioButton';
import { images } from '../constants';
import CustomButton from './CustomButton';
import {Search} from '../shared_elements';

export default function RadioButtonDialog(props){
    const { value, label, required, btnText, multi=true } = props

    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(value);
    const [tags, setTags] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchedTags, setSearchedTags] = useState([]);
    const [options, setOptions] = useState([]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleChangeOption = (value) => {
        if(values.includes(value)){
            setValues(values.filter(val => val != value))
        }else{
            setValues([...values, value])
        }
        if(!options.includes(value)){
          setSearchValue('');
          setOptions([
            ...options,
            ...[value]
          ])
        }
    }

    const handleSubmit = () => {
        if(values){
            props.handleSubmit(values)
        }
        handleClose()
    }

    useEffect(() => {
        if(open){
            setValues(value);
            let newOptions = props?.value && props.value.length ? props.value.filter(val => !props.options.includes(val)) : [];
            let valuesUpdated = [...newOptions, ...props.options];
            setOptions(valuesUpdated);
            setSearchValue('')
        }
        setTags(options);
    }, [open, props.options])

    const handleSearch = (value) => {
      setSearchValue(value);
      const str = value.toLowerCase();
      const filteredTags = options?.filter(
        (option) => option && option.toLowerCase().includes(str)
      );
      setSearchedTags(filteredTags);
    };

    return(
        <div className="customRadioButtonDialogWrapper">
            {label ? <label>{`${label}`}<span className="MuiFormLabel-asterisk">{required ? ' *' : ''}</span></label> : null}
            {
                value.length >= 1 && !open ? <List className="listInline customRadioButtonList">
                    {
                        value.map((val, index) => <ListItem kwy={index} className="listInlineItem customRadioButton active multi">
                            <a className="heading5Medium">
                                <span>{val}</span>
                                <img src={images.closeIcon} className="closeIcon" onClick={() => props.handleChange(val)}/>
                            </a>
                        </ListItem>)
                    }
                    <ListItem className="listInlineItem">
                        <h3 className="heading5Bold addBtn">
                            <span onClick={handleClickOpen}>+ {btnText}</span>
                        </h3>
                    </ListItem>
                </List> : <h3 className="heading5Bold addBtn">
                    <span onClick={handleClickOpen}>+ {btnText}</span>
                </h3>
            }

            <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" className="customRadioButtonDialog" open={open}>
                <DialogContent className="dialogContent">
                    {label ? <h3 className="heading4Medium title">{`${label}`}<span className="MuiFormLabel-asterisk">{required ? ' *' : ''}</span></h3>: null}
                    <div className="searchOuterWrapper">
                      <Search
                        handleSearch={handleSearch}
                        text={searchValue}
                      />
                    </div>
                    {searchValue ? (
                      <>
                        {searchedTags.length  ? (
                        <>
                          <List className="listInline customRadioButtonList">
                          {searchedTags.map((tag, key) =>
                            <ListItem key={key} className={`listInlineItem customRadioButton ${values.includes(tag) ? 'active' : ''}`} onClick={() => handleChangeOption(tag)}>
                                <a className="heading5Medium" style={{padding: '10px 20px 8px'}}>
                                    <span>{tag}</span>
                                </a>
                            </ListItem>
                          )}
                          </List>

                        </>
                        )
                        : (
                          <>
                            <div className="createSearchWrapper">
                              <p onClick={() => handleChangeOption(searchValue)}>
                                Create "{searchValue}"
                              </p>
                            </div>
                            <div className={`contentBlk`}>
                              <p
                                className={
                                  `heading3Medium noSearchResult`
                                }
                              >
                                No result found
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) :
                      <CustomRadioButton
                          options={options}
                          value={values}
                          handleChange={handleChangeOption}
                          multi={true}
                      />
                    }

                    {/* <div className="dialogFooter">

                    </div> */}

                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={handleClose} className="heading5Bold">
                        Cancel
                    </CustomButton>
                    <CustomButton onClick={handleSubmit} className="primaryBtn">
                        Done
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}
