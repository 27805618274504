import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {  List, ListItem } from '@material-ui/core';
import { images } from '../constants';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {GoogleSearchSuggestion} from './index';

export default function GoogleRegionSearch(props){
  const [input, setInput] = useState(props.value);
  const onChange = (e, val) => {
    if(val && val.place_id){
      getPlacesPostCodeById(val.place_id).then(response => props.onPlaceSelected({...response, details: val}));
    }else{
      props.onPlaceSelected({
        pincode : '',
        city : '',
        state : '',
        details : {
          description : ''
        }
      });
    }
  }
  
  const getPlacesPostCodeById = async (placeId) => {
    return new Promise((resolve, reject) => {
      if (!placeId) reject('placeId not provided');
      try {
        new window.google.maps.places.PlacesService(
          document.createElement('div')
        ).getDetails(
          {
            placeId,
            fields: ['address_components', 'formatted_address'],
          },
          (details) => {
            if (details) {
              let customAddress = {
                city: '',
                state: '',
                country: '',
                pincode: '',
              };
              const { address_components } = details;
              if (address_components?.length !== 0) {
                address_components.forEach((data) => {
                  if (data.types.includes('country')) {
                    customAddress.country = data.long_name;
                  }
                  if (data.types.includes('administrative_area_level_1')) {
                    customAddress.state = data.long_name;
                  }
                  if (data.types.includes('locality')) {
                    customAddress.city = data.long_name;
                  }else if (data.types.includes('administrative_area_level_2') && customAddress.city == '') {
                    customAddress.city = data.long_name;
                  }
                  if (data.types.includes('postal_code')) {
                    customAddress.pincode = data.long_name;
                  }
                  if (data.types.includes('postal_code_suffix')) {
                    customAddress.pincode =
                      data.long_name + '-' + customAddress.pincode;
                  }
                });
              }
              return resolve(customAddress);
            }
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const suggestions = GoogleSearchSuggestion(input);
  return (
    <div className="inputGroup googleSearchGroup">
      <label>{props.label} {props.required ? <span className="MuiFormLabel-asterisk">*</span> : null}</label>
      <Autocomplete
        id="custom-input-demo"
        options={suggestions && suggestions.length ? suggestions : [] }
        getOptionLabel={(option) => option.description ? option.description : option}
        getOptionSelected={(option) => option.description == props.value}
        defaultValue={props.value}
        className="autocomplete"
        targetorigin={{ vertical: "bottom", horizontal: "left" }}
        freeSolo
        // placeholder="Enter a location or street address"
        onChange={onChange}
        onInputChange={(e, v) => {setInput(v); props.onPlaceSelected({
          details : {
            description : v
          }
        });}}
        renderInput={(params) => (
          <div className="outerWrapper" ref={params.InputProps.ref}>
              <input type="text" {...params.inputProps} placeholder={props.placeholder ? props.placeholder : "Enter a loaction or street address"} />
              <span className="searchIcon" >
                <img src={images.searchIcon} alt="search" />
              </span>
          </div>
        )}
      />
    </div>
  );
}
