import React, {useState} from 'react'
import { CustomButton } from '../../../shared_ui_components';
import styles from '../../../assets/styles/home.module.scss';
import { Container, Grid, List, ListItem, Hidden } from '@material-ui/core';
import { images } from '../../../constants';

export default function PageTitleBlk(props){
    
    const { activeSection, warning, errorObj, setNextSectionIndex, setOpenWarning, activeIndex } = props

    return(
        <div className={styles.pageTitleBlk}>
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                    <div className={styles.pageTitle}>
                        <h3 className={`heading3SemiBold ${styles.heading3SemiBold}`}>Startup Mojo Quiz </h3>
                    </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Hidden only={['xs', 'sm']}>
                            <List className={styles.saveBtnBlk + ' listInline'}>
                                {/* {activeIndex >= 1 ? <ListItem className="listInlineItem">
                                    <a className={styles.goBack +' heading5Bold'} onClick={() => {
                                        if(warning){
                                            setOpenWarning(true);
                                            setNextSectionIndex(activeIndex - 1)
                                        }else{
                                            props.handleChangeSection(activeIndex - 1);
                                        }
                                    }}>
                                        <img src={images.backArrow} className={styles.arrowBackIcon}/>
                                        <span>Go Back</span>
                                    </a>
                                </ListItem> : null} */}
                                <ListItem className="listInlineItem">
                                    <CustomButton className={`primaryBtn ${Object.keys(errorObj).every(key => errorObj[key] === "") ? '' : 'disabled'}`} onClick={() => props.handleSubmitSection('next')}>Save & Next</CustomButton>
                                </ListItem>
                            </List>
                        </Hidden>
                        <Hidden only={['md', 'lg','xl']}>
                            <p className={`${styles.menuIcon} textRight`} ><a><img onClick={props.handleClickMenu} src={images.menuIcon}/></a></p>
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden only={['md', 'lg','xl']}>
                    <div className={styles.pageFooterBlk}>
                        <Grid container alignItems="center" justify="flex-end">
                            <List className={styles.saveBtnBlk + ' listInline'}>
                                <ListItem className="listInlineItem">
                                    <CustomButton className={`primaryBtn ${Object.keys(errorObj).every(key => errorObj[key] === "") ? '' : 'disabled'}`} onClick={() => props.handleSubmitSection('next')}>Save & Next</CustomButton>
                                </ListItem>
                            </List>
                        </Grid>
                    </div>
                </Hidden>
            </Container>
        </div>
    )
}
