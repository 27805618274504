import React from 'react'
import { CircularProgress, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { images } from '../constants';

const useStylesFacebook = makeStyles((theme) => ({
    bottom: {
      color: '#DDDDDD',
    },
    successColor: {
      color: '#0ACF83',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    warningColor: {
      color: '#FFC107',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    errorColor: {
      color: '#D03630',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));

export default function CustomCircularProgress(props){
    const classes = useStylesFacebook();
    const { value, order } = props;
    return(
        <div className={`circularProgressWrapper`}>
          {
          props.value >= 100 ? 
          <img className={`stepCompleted`}  src={images.stepCompleted} />
          :
          <>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={26}
                thickness={5}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                disableShrink
                className={
                  props.value === 100 ? classes.successColor
                  :
                  (props.value >= 50 && props.value < 100) ? classes.warningColor
                  : 
                  (props.value > 0 && props.value < 50) ? 
                    classes.errorColor
                  : classes.successColor
                }
                classes={{
                    circle: classes.circle,
                }}
                size={26}
                thickness={5}
                {...props}
                value={props.value}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={`label`}
            >
                <span className="supportText">{order}</span>
            </Box>
          </>
        }
      </div>
    )
}