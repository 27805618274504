import React, { useEffect } from 'react';
import { ListItem, List } from '@material-ui/core';

export default function CustomRadioButton(props) {
  const { options=[], value, label, required, multi=false } = props
  return (
    <>
    {label ? <label>{`${label}`}<span className="MuiFormLabel-asterisk">{required ? ' *' : ''}</span></label> : null}
    <List className="listInline customRadioButtonList">
      { options && options.map((option, index) => <ListItem key={index} className={`listInlineItem customRadioButton ${multi ? value.includes(option) ? 'active': '' : value == option ? 'active': ''}`} onClick={() => props.handleChange(option)}>
        <a className="heading5Medium">
          <span>{option}</span>
        </a>
      </ListItem>)}
    </List>
    </>
  );
}
