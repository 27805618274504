import React from 'react'
import { Grid, List, ListItem } from '@material-ui/core'
import styles from '../../../assets/styles/home.module.scss';
import { GoogleRegionSearch } from '../../../shared_ui_components';
import { images } from '../../../constants';

export default function RegionSearch(props){
    const { question, handleChange, questions } = props;
    const onSelect = (place) => {
        const pinCode = questions.find(question => question.parent_type === 'region_search_pin_code');
        const city = questions.find(question => question.parent_type === 'region_search_city');
        const state = questions.find(question => question.parent_type === 'region_search_state');
        if(pinCode && pinCode.id && place && place?.pincode){
            handleChange(pinCode.id, place.pincode);
        }
        if(city && city.id && place && place?.city){
            handleChange(city.id, place.city);
        }
        if(state && state.id && place && place?.state){
            handleChange(state.id, place.state);
        }
        handleChange(question.id, place.details.description);
    }
    return(
        <GoogleRegionSearch 
            label={question.title}
            placeholder={question.placeholder}
            required={question.mandatory}
            value={question.answer}
            onPlaceSelected={(place) => onSelect(place)}
        />
    )
}