import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import styles from '../../../assets/styles/result.module.scss';
import { AssessmentResult } from '../components'
import { globalGetService } from '../../../utils/globalApiServices';

export default function Result(props){
    return(
        <div className={styles.resultPage}>
            <section className={styles.resultSection}>
                <Container className={styles.resultContainer}>
                    <AssessmentResult {...props}/>
                </Container>
            </section>
        </div>
    )
}