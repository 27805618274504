import "date-fns";
import React, {useState, useEffect} from "react";
import { format, add } from 'date-fns'
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { images } from '../constants';

export default function Calender(props) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = useState(props.value);
  const [open, setOpen] = useState(false);

  const handleDateChange = (date=null) => {
    setSelectedDate(date);
    props.onChange(date);
  };

  useEffect(() => {
    setSelectedDate(props.value);
  }, [props.value])

  return (
    <div className="inputGroup calenderGroup">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* <Grid container justify="space-around"> */}
          {
            props.label ?
            <label className="label">{props.label}<span className="MuiFormLabel-asterisk">{props.required ? ' *' : ''}</span></label>
            : null
          }
          <div className="outerWrapper">
            <DatePicker
              placeholder={props.placeholder}
              variant="inline"
              openTo="year"
              views={["year", "month"]}
              value={selectedDate ? selectedDate : null}
              onChange={handleDateChange}
              autoOk={true}
              PopoverProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right', },
                transformOrigin: { vertical: 'bottom', horizontal: 'center', }
              }}
              minDate={format(new Date(), 'yyyy-MM-dd')}
              maxDate={add(new Date(), { years: 6 })}
            />
            <span className="calederIcon hidden">
              <img
                src={images.calederIcon}
                alt="calendar"
                style={{padding:'12px'}}
              />
            </span>
          </div>
        {/* </Grid> */}
      </MuiPickersUtilsProvider>
      {
         props.error ?
         <h6 className="errorMsg">
           {props.error}
         </h6>
         :
         null
      }
    </div>
  );
}
