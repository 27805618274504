import React from 'react'
import { CircularProgress, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/styles/result.module.scss';
import { images } from '../../../constants';
import { isMobile } from 'react-device-detect';

const useStylesFacebook = makeStyles((theme) => ({
    bottom: {
      color: '#DDDDDD',
    },
    primary: {
      color: '#7D201D',
      animationDuration: '550ms',
      position: 'absolute',
      left: isMobile ? `28.5%` : `38.5%`,
    },
    warningColor: {
      color: '#FFC107',
      animationDuration: '550ms',
      position: 'absolute',
      left: `38.5%`
    },
    errorColor: {
      color: '#D03630',
      animationDuration: '550ms',
      position: 'absolute',
      left: `38.5%`
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));

export default function SuccessProgress(props){
    const classes = useStylesFacebook();
    const { value } = props;
    return(
        <div className={styles.circularProgressWrapper}>
          <>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={150}
                thickness={3}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                disableShrink
                className={classes.primary}
                classes={{
                    circle: classes.circle,
                }}
                size={150}
                thickness={3}
                {...props}
                value={props.value}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={styles.label}
            >
                <span className={`heading1 ${styles.heading1}`}>{`${Math.floor(value)}%`}</span>
            </Box>
          </>
      </div>
    )
}