import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import {VerificationBlk, Shimmer} from '../components';
import {images} from '../../../constants';
import { emailVerificationApi } from '../apiServices'
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Footer} from '../../../shared_elements'

export default class EmailVerification extends Component{
  constructor(props){
    super(props);
    this.state = {
      loader: true,
      resendLoader: false,
      emailVerified: false,
      errorMessage: '',
      email: '',
      token: '',
      fullName: ''
    }
    this.emailVerificationApi = emailVerificationApi.bind(this)
  }
  componentDidMount(){
    const querys = queryString.parse(this.props.location.search, {
      decode: false,
    });
    const state = querys.state;
    if(state && this.props.location.pathname.includes('verify')){
      this.emailVerificationApi({state})
    }else if(this.props.history.location.state){
      const { formData } = this.props.history.location.state
      if(formData.email && formData.full_name){
        this.setState({
          email: formData.email,
          loader: false,
          fullName: formData.full_name
        })
      }
    }else if(localStorage.getItem('userInfo') && localStorage.getItem('access')){
      this.props.history.push('/')
    }else{
      this.props.history.push('/register')
    }
  }
  render(){
    const { email, fullName, loader, emailVerified } = this.state;
    return(
      <>
        <div className={styles.emailverificationHeader + ' primaryHeader'}>
          <div className={styles.headerWrapper + ' headerWrapper'}>
            <Container maxWidth="lg">
              <Grid container direction="row" justify="center" alignItems="center" className="headerRow">
                <Grid item md={12} sm={12} xs={12}>
                  <Link to="/">
                    <img src={images.logoSvg} alt="Product10x" />
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="emptyHeaderdiv"></div>
        <div className={styles.emailVerifcationPage}>
          <section className={styles.emailVerificationSection + ' flexCentered'}>
            <Container maxWidth={"lg"} className={styles.emailVerificationContainer}>
                <Grid container spacing={isMobile ? 1 : 2} className={styles.emailVerificationRow}>
                  <Grid item xs={12} md={12}>
                    {loader ? <Shimmer type="Verification" /> : emailVerified ? '' : <VerificationBlk email={email} fullName={fullName}/>}
                  </Grid>
                </Grid>
            </Container>
          </section>
        </div>
        <Footer />
      </>
    )
  }
}
